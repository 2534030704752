@import '../../../styles/Shared.scss';

.container {
  @include row(center, center);
  width: 100%;
  margin-top: $space-m;
}

.dot {
  cursor: pointer;
}