@import '../../styles/Shared.scss';

.container {
  @include row(center, center);
  width: 100%;
  margin: $space-s;
}

.columnContainer {
  @include col(flex-start, center);
}

.columnContainer p {
  margin: $space-s;
  font-size: $font-s;
}

.logoContainer {
  height: 50px;
}
