@import '../../../styles/Shared.scss';

.container {
  @include col;
  max-width: 300px;
  height: 365px;
  margin: $space-l 0;
}

.image {
  width: 100%;
  max-height: 300px;
}

.title {
  margin-top: $space-xxl 0;
  font-size: $font-m;
}

.description {
  margin: $space-m 0;
  font-weight: 300;
  font-size: $font-default;
}
