@import '../../styles/Shared.scss';

.container {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 2000;
  display: block;
}

.image {
  width: 45px;
  height: 45px;
}

.shadowBox {
  display: block;
  position: fixed;
  right: 20px;
  bottom: 23px;
  z-index: 1000;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  border: none;
  border-radius: 30px;
}