@use '../../styles/Shared.scss' as *;

.container {
  @include col;
}

.rowContainer {
  @include row(space-around, flex-start);
  width: 100%;
}

.columnContainer {
  @include col(flex-start, flex-start);
  width: 100%;
  padding: $space-m;
  box-sizing: border-box;
}

.columnContainer .title {
  font-size: $font-m;
  font-weight: bold;
  margin-bottom: $space-m;
}

.columnContainer p {
  margin: $space-s;
  text-align: left;
  font-size: $font-14px;
  line-height: 25px;
}

.columnContainer p a {
  color: inherit;
  text-decoration: none;
}

.columnContainer p svg {
  margin-right: $space-s;
  vertical-align: sub;
}

@media screen and (max-width: $media-s) {
  .rowContainer {
    @include col(flex-start, flex-start);

    .columnContainer {
      align-items: flex-start;
      text-align: start;
      margin-top: $space-xxl;
    }
  }
}

.logoContainer {
  height: 70px;
}