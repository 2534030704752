@import '../../styles/Shared.scss';

.container {
  @include col;
}

.description {
  font-size: $font-m;
  font-weight: 300;
  line-height: 1.6rem;
}

.servicesContainer {
  margin-top: $space-xxl;
  @include row(space-between);
  width: 100%;
}

@media screen and (max-width: $media-s) {
  .servicesContainer {
    @include col(flex-start, center);
  }
}
