@import '../../styles/Shared.scss';

.container {
  @include col(center, center);
  width: 100%;
}

.trailerDataContainer {
  @include col(center, center);
  width: 100%;
}

.title {
  margin: 0;
  margin-top: $space-xxl;
  font-size: $font-m;
}

.price {
  margin-top: $space-m;
  font-weight: 700;
  font-size: $font-m;
  color: $color-primary;
  font-style: italic;
}

.image {
  width: 80%;
  max-height: 360px;
}

.chipsContainer {
  @include row(center, center);
  flex-wrap: wrap;
  margin-top: $space-m;
}

.actionButtonsContainer {
  @include row(center, center);
  margin-top: $space-m;
}

