@import '../../styles/Shared.scss';

.button {
  border: solid 1px;
  border-radius: $border-radius;
  margin: $space-l;
  transition: all 0.2s ease-out;
  font-size: 15px;
  text-decoration: none;
}

.button:first-of-type {
  margin-left: 0;
  margin-right: $space-l;
}

.button:last-of-type {
  margin-left: $space-l;
  margin-right: 0;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $space-s;
}

.primary {
  background-color: $color-primary;
  border-color: $color-primary;
  color: white;
}

.primary:hover {
  background-color: transparent;
  border: 1px solid $color-primary;
  color: inherit;
}

.secondary {
  background-color: transparent;
  border: 1px solid $color-primary;
}

.secondary:hover {
  background-color: $color-primary;
  border-color: $color-primary;
  color: white;
}

.iconContainer {
  margin: 0 $space-s;
  text-align: center;
  @include row(center, center)
}

.link {
  text-decoration: none;
  color: inherit;
}
