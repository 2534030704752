@import '../../styles/Shared.scss';

.container {
  padding: $space-xs;
  border: 1px solid $color-border;
  border-radius: 10px;
  background-color: $color-bg-secondary;
  margin: $space-s;
}

.text {
  font-size: $font-xs;
  padding: 0 $space-xs;
}

