@import '../../styles/Shared.scss';

.container {
  @include row(space-between);
}

.leftContainer {
  @include col(flex-start, flex-start);
  text-align: left;
  width: 100%;
}

.iconsContainer {
  @include row(flex-start, center);
}

.rightContainer {
  text-align: right;
  width: 100%;
}

.image {
  width: 100%;
}

@media screen and (max-width: $media-m) {
  .container {
    @include col(flex-start, center);
  }

  .leftContainer {
    @include col(flex-start, center);
    text-align: center;
    width: 100%;
  }

  .rightContainer {
    text-align: center;
    width: 100%;
  }
}
