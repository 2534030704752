@import '../../styles/Shared.scss';

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  z-index: 999999;
  opacity: 0;
  border-radius: 0;
  border: 1px solid transparent;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  color: #ecf0f1;
  background: #212327;
  padding: $space-m $space-xl;
  text-align: center;
  box-sizing: border-box;
  line-height: 25px;

}

.containerShow {
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 1000ms;
}

.button {
  margin-left: 10px;
  vertical-align: baseline;
  padding: 10px 30px;
  color: #fff;
  font-weight: 400;
  border-radius: 30px;
  line-height: 1.5;
  border: 1px solid transparent;
  background-color: #007bff;
  border-color: #007bff;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s linear;
}

.button:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}