@import '../../styles/Shared.scss';

.navbar {
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem;
  background-color: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 90;
}

.navbarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

.navItemsContainer {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  z-index: 999;
}

.desktop {
  flex-direction: row;
  justify-content: flex-end;
}

.mobile {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 0;
  position: fixed;
  top: 6rem;
  left: 0;
  margin: 0 auto;
  width: 100%;
  background-color: white;
  transition: max-height 0.2s ease-out;
}

.drawerContainerShow {
  max-height: 160px;
  border-bottom: 1px solid $color-border;
}

.navItem {
  margin: 0 1rem;
}

.drawerContainer .navItem {
  margin: 0.4rem;
}

.navLink {
  text-decoration: none;
  color: $color-primary;
  position: relative;
  font-size: $font-s;
}

.navLink:hover {
  color: $color-accent;
}

.navLink::before {
  background-color: $color-accent;
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  margin: 0 auto;
  width: 0;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: width 1s;
  -webkit-transition: width 1s;
}

.navLink:hover::before {
  width: 100%;
}

.navActionButton {
  text-decoration: none;
  font-size: $font-s;
  background-color: $color-primary;
  color: $color-bg-secondary;
  padding: $space-m;
  border-radius: $border-radius;
}
