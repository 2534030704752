@import '../../styles/Shared.scss';

.container {
  @include col;
}

.description {
  font-size: $font-m;
  font-weight: 300;
  line-height: 1.6rem;
}
