$color-primary: #343a40;
$color-accent: #ff5555;
$color-bg-secondary: rgb(249, 249, 249);
$color-border: rgba(0, 0, 0, 0.125);
$color-divider: rgba(138, 138, 138, 1);

$space-xs: 0.2rem;
$space-s: 0.375rem;
$space-m: 0.75rem;
$space-l: 1rem;
$space-xl: 1.2rem;
$space-xxl: 2rem;
$space-xxxl: 6rem;

$font-xs: 0.825rem;
$font-s: 0.9rem;
$font-default: 1rem;
$font-m: 1.2rem;
$font-l: 1.5rem;
$font-xl: 3rem;
$font-14px: 0.875rem;

$border-radius: 5px;

$media-xs: 575px;
$media-s: 576px;
$media-m: 768px;
$media-l: 992px;

$max-width: 1140px;

@mixin row($justify-content: center, $align-items: center) {
  display: flex;
  flex-direction: row;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin col($justify-content: center, $align-items: center) {
  display: flex;
  flex-direction: column;
  justify-content: $justify-content;
  align-items: $align-items;
}
