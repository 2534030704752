@import '../../styles/Shared.scss';

.container {
  position: relative;
  width: 100%;
  @include col(flex-start, center);
}

.buttonContainer {
  position: absolute;
  width: 15%;
  height: 100%;
  top: 0;
  @include col(center, center);
  padding: $space-s;
  cursor: pointer;
}

.buttonContainerLeft {
  @extend .buttonContainer;
  left: 0;
}

.buttonContainerRight {
  @extend .buttonContainer;
  right: 0;
}

.carouselContainer {
  @include row(flex-start, center);
  overflow: hidden;
}

.carouselItemContainer {
  @include col(center, center);
  width: 100%;
  opacity: 0;
  display: none;
  transition: opacity 0.5s ease;
}

.activeCarouselItemContainer {
  opacity: 1;
  display: block;
}

