@import '../../../styles/Shared.scss';

.container {
  cursor: pointer;
  @include row();
  height: 20px;
  width: 20px;
}

.icon {
  width: 100%;
  height: 100%;
}
