@import '../styles/Shared.scss';

.container {
  @include col(flex-start, flex-start);
  max-width: $max-width;
  padding: 0 $space-s;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  line-height: 1.5;
}

.orderedList {
  margin: 10px 0px; 
  padding: 15px
}

@media screen and (max-width: $media-s) {
  .container {
    @include col(flex-start, center);
    text-align: center;
    padding: 0 $space-l;
  }
}