@import '../../styles/Shared.scss';

.container {
}

.title {
  position: relative;
  font-size: $font-l;
  font-style: italic;
}

.title::before {
  background-color: $color-accent;
  content: '';
  display: block;
  height: 1px;
  position: absolute;
  margin: 0 auto;
  width: 35%;
  top: 35px;
  left: 0;
  right: 0;
  bottom: 0;
}
